:root {
    --primary-color: rgb(189, 104, 18);
    --primary-background-color: #4c4c56;
    --color-completed-opacity: rgba(32, 228, 120, 0.2);
    --color-cancelled-opacity: rgba(228, 32, 32, 0.2);
    --color-pending-opacity: rgba(32, 166, 228, 0.2);
    --color-completed: #16c364;
    --color-cancelled: #e02020;
    --color-pending: #0baabf;
}

$primary-color: var(--primary-color);
$primary-background-color: var(--primary-background-color);

$body: #f4f5fa !important;
$rgb-primary-color: 242, 125, 20;
$primary-border-color: #c21b55;
$success-color: #1fa200;
$danger-color: #ff5353;
$success-color-light: #e2f2e3;
$gray-primary: #5c5c5c;
$background-header-th: #f9fafc;
$primary-color-hover: rgb(211, 92, 92);
$primary-color-light: #fae6e6;
$primary-warning-light: #faf6e6;

.light-theme {
    --primary-color: #{$primary-color};
    --bg-header-th: #{$background-header-th};
    --danger-color: #{$danger-color};
    --primary-background-color: #{$primary-background-color};
    --rgb-primary-color: #{$rgb-primary-color};
}

.dark-theme {
    --bg-header-th: #{$background-header-th};
    --color-completed-opacity: rgba(32, 228, 120, 1);
    --color-cancelled-opacity: rgba(228, 32, 32, 1);
    --color-pending-opacity: rgba(228, 228, 32, 1);
    --color-completed: #16c364;
    --color-cancelled: #e02020;
    --color-pending: #e0e020;
}
