@import "bootstrap/scss/bootstrap";
@import "./variables.scss";

@font-face {
    font-family: "Montserrat";
    src:
        url("/fonts/Montserrat/montserrat-light-webfont.woff2") format("woff2"),
        url("/fonts/Montserrat/montserrat-light-webfont.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src:
        url("/fonts/Montserrat/montserrat-regular-webfont.woff2") format("woff2"),
        url("/fonts/Montserrat/montserrat-regular-webfont.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src:
        url("/fonts/Montserrat/montserrat-bold-webfont.woff2") format("woff2"),
        url("/fonts/Montserrat/montserrat-bold-webfont.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src:
        url("/fonts/Montserrat/montserrat-semibold-webfont.woff2") format("woff2"),
        url("/fonts/Montserrat/montserrat-semibold-webfont.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

body {
    font-family: "Montserrat", sans-serif !important;
    --bs-body-bg: #f7f7f7;
}

html {
    scroll-behavior: smooth;
}

body,
html {
    height: 100%;
    margin: 0;
    padding: 0;
}

#page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#content {
    flex: 1;
}

a {
    text-decoration: none;
}

h3 {
    font-size: 1.3rem;
}

.form-control {
    padding: 0.7rem 0.75rem;
}

.form-control:focus {
    box-shadow: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.sectionTitle {
    padding: 0.4rem 0;
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 1rem;

    @media (min-width: 992px) {
        font-size: 1.4rem;
    }
}

.btn-primary {
    --bs-btn-bg: var(--primary-color);
    --bs-btn-border-width: 0px;
    --bs-btn-border-radius: 3rem;
    --bs-btn-active-bg: var(--primary-color);
    --bs-btn-active-border-color: #fff;

    &:hover {
        filter: brightness(95%);
        --bs-btn-hover-bg: var(--primary-color);
        --bs-btn-hover-border-color: #fff;
    }
}

.form-check-input:checked {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.form-check-input:focus {
    border-color: var(--primary-color) !important;
    box-shadow: none !important;
}

.review-swiper-button-prev,
.review-swiper-button-next {
    top: 50%;
    position: absolute;

    @media (max-width: 767.98px) {
        display: none;
    }
}

.review-swiper-button-prev {
    left: -3rem;
}

.review-swiper-button-next {
    right: -3rem;
}

.errorInput {
    font-size: 0.7rem;
    color: #ff5353;
    position: absolute;
    margin-top: 2px;
    font-weight: bold;
}

.articleDetail {
    border: 0;
    padding: 1rem;
    background: #fff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05);

    h1,
    h2,
    h3 {
        font-size: 1.3rem;
        font-weight: bold;
    }

    img {
        @media (max-width: 767.98px) {
            max-width: 100%;
        }
    }
}

.btn-link {
    --bs-btn-color: var(--primary-color);
    text-decoration: none;
    --bs-btn-hover-color: #333;
}

.btn-black {
    background-color: var(--primary-background-color);
    color: #fff;
    font-size: 0.85rem;
    &:hover {
        background-color: var(--primary-color);
    }
}

button {
    border-radius: 3rem;
}

.img-fluid {
    width: 100%;
}

.btn:disabled,
.btn.disabled {
    background: #d4d4d4;
    color: #fff;
    cursor: not-allowed;
    border: 1px solid #ccc !important;
    pointer-events: auto;
}

.resize-none {
    resize: none;
}

.completed {
    color: var(--color-completed);
}

.cancelled {
    color: var(--color-cancelled);
}

.pending {
    color: var(--color-pending);
}

.carousel-indicators [data-bs-target] {
    width: 10px !important;
    height: 10px !important;
    border: 0 !important;
}

@media (max-width: 767.98px) {
    iframe {
        width: 100%;
    }
}

.breadcrumb-item.active {
    color: var(--primary-color);
}

// :not(.btn-check) + .btn:active,
// .btn:first-child:active,
// .btn.active,
// .btn:focus-visible,
// .btn.show {
//     background: none !important;
// }

.accordion-button,
.accordion-item:first-of-type,
.accordion-item:last-of-type,
.accordion-item:first-of-type > .accordion-header .accordion-button,
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
    border-radius: 0;
}

.youtube-wrapper {
    position: relative;
    margin-top: 7%;
    margin-bottom: 7%;
}

.youtube-wrapper iframe:first-child {
    top: 0;
}

.youtube-wrapper iframe {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}

.youtube-wrapper iframe {
    width: 90%;
    margin: 0 auto;

    @media (min-width: 992px) {
        width: 75%;
        min-height: 400px;
    }
}

.youtube-section-background,
.youtube-section-background-second {
    width: 100vw;
    position: relative;
    z-index: 1;
    margin-top: 150px;
    margin-bottom: 150px;
    height: 180px;
}
